import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import styled from 'styled-components';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { saasModernTheme } from 'common/src/theme/saasModern';
import { ResetCSS } from 'common/src/assets/css/style';
import { GlobalStyle, ContentWrapper } from '../containers/sassModern.style';

import BannerSectionSmall from '../containers/BannerSmall';
import Navbar from '../containers/Navbar';
import Footer from '../containers/Footer';
import SEO from '../components/seo';
import Box from '../../../common/src/components/Box';
import Container from '../../../common/src/components/UI/Container';
import DownloadSection from '../containers/DownloadSection';
import CTASection from '../containers/CTA';
import CheckBox from '../../../common/src/components/Checkbox';
import Button from '../../../common/src/components/Button';
import { useTranslation, Trans } from 'react-i18next';

const SliderContainer = styled.div`
  margin: 16px 0;
  display: flex;
  align-items: center;
`;

const SliderInputContainer = styled.div`
  position: relative;
  width: 100%;
`;

const InputLabel = styled.label`
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 500;
  color: #767676;
`;

const TextField = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
  box-sizing: border-box;
  margin-right: 16px;

  &:focus {
    outline: none;
    border-color: #2aa275;
  }
`;

const IconButton = styled.button`
  position: absolute;
  right: ${(props) => (props.rightOffset ? props.rightOffset : '10px')};
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
  }
`;

const SliderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 24px;
`;

const CustomSlider = styled.input.attrs({ type: 'range' })`
  width: 100%;
  height: 8px;
  background: #ddd;
  border-radius: 4px;
  appearance: none;
  cursor: pointer;

  &::-webkit-slider-thumb {
    appearance: none;
    width: 24px;
    height: 24px;
    background-color: #fff;
    border: 2px solid #2aa275;
    border-radius: 50%;
    cursor: pointer;
    transition: box-shadow 0.3s;

    &:hover {
      box-shadow: 0 0 0 8px rgba(42, 162, 117, 0.2);
    }
  }
`;

const CopyIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
    >
      <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-4 12h6a2 2 0 002-2v-8a2 2 0 00-2-2h-6a2 2 0 00-2 2v8a2 2 0 002 2z"
      />
    </svg>
);

const RefreshIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 48 48"
        fill="currentColor"
    >
      <path d="M35.3 12.7c-2.89-2.9-6.88-4.7-11.3-4.7-8.84 0-15.98 7.16-15.98 16s7.14 16 15.98 16c7.45 0 13.69-5.1 15.46-12h-4.16c-1.65 4.66-6.07 8-11.3 8-6.63 0-12-5.37-12-12s5.37-12 12-12c3.31 0 6.28 1.38 8.45 3.55l-6.45 6.45h14v-14l-4.7 4.7z" />
      <path d="M0 0h48v48h-48z" fill="none" />
    </svg>
);

const PasswordLengthSelector = ({ passwordLength, setPasswordLength }) => {
  const { t } = useTranslation();

  const handleSliderChange = (event) => {
    setPasswordLength(Number(event.target.value));
  };

  const handleInputChange = (event) => {
    const value = event.target.value === '' ? '' : Number(event.target.value);
    setPasswordLength(value);
  };

  const handleBlur = () => {
    if (passwordLength < 8) {
      setPasswordLength(8);
    } else if (passwordLength > 1024) {
      setPasswordLength(1024);
    }
  };

  return (
      <SliderContainer>
        <div style={{ width: '25%' }}>
          <InputLabel htmlFor="password-length">{t('Password Length')}</InputLabel>
          <TextField
              value={passwordLength}
              onChange={handleInputChange}
              onBlur={handleBlur}
              id="password-length"
              type="number"
              min="8"
              max="1024"
              step="1"
          />
        </div>
        <SliderWrapper style={{ width: '75%' }}>
          <CustomSlider
              value={passwordLength}
              onChange={handleSliderChange}
              min="8"
              max="128"
          />
        </SliderWrapper>
      </SliderContainer>
  );
};

const Content = styled.div`
  font-family: 'Roboto', sans-serif;
  line-height: 2;
  color: #343d48cc;

  a {
    color: #343d48cc;
    text-decoration: underline;
  }

  ul {
    margin-left: 40px;
  }

  li {
    list-style-type: disc;
  }

  h2 {
    font-weight: 500;
  }
`;

export default ({ pageContext }) => {
  const { t } = useTranslation();

  const [passwordLength, setPasswordLength] = useState(16);
  const [includeSymbols, setIncludeSymbols] = useState(false);
  const [includeNumbers, setIncludeNumbers] = useState(true);
  const [includeLowercaseCharacters, setIncludeLowercaseCharacters] = useState(
      true
  );
  const [includeUppercaseCharacters, setIncludeUppercaseCharacters] = useState(
      true
  );
  const [excludeSimilarCharacters, setExcludeSimilarCharacters] = useState(
      true
  );
  const [excludeAmbiguousCharacters, setExcludeAmbiguousCharacters] = useState(
      false
  );

  const generatePassword = useCallback(() => {
    let characters = '';
    let i;
    let password = '';

    if (typeof window === 'undefined') {
      return '';
    }

    const random = function () {
      let bs;
      let byte;

      if (window && window.crypto && window.crypto.getRandomValues) {
        bs = new Uint32Array(1);
        window.crypto.getRandomValues(bs);
        byte = bs[0];
      } else if (window && window.msCrypto && window.msCrypto.getRandomValues) {
        bs = new Uint32Array(1);
        window.msCrypto.getRandomValues(bs);
        byte = bs[0];
      } else {
        throw new Error('No cryptographic random number generator');
      }

      return byte / (0xffffffff + 1);
    };

    if (includeSymbols) {
      characters = characters + '-_#+*!§$%&=?@';
    }
    if (includeNumbers) {
      characters = characters + '0123456789';
    }
    if (includeUppercaseCharacters) {
      characters = characters + 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    }
    if (includeLowercaseCharacters) {
      characters = characters + 'abcdefghijklmnopqrstuvwxyz';
    }
    if (includeSymbols && !excludeAmbiguousCharacters) {
      characters = characters + ',.;:\'~"/(){}[]\\';
    }

    if (excludeSimilarCharacters) {
      const similarCharacters = ['o', '0', 'O', 'i', 'l', '1', 'L'];
      for (i = 0; i < similarCharacters.length; i++) {
        characters = characters.replace(
            new RegExp(similarCharacters[i], 'g'),
            ''
        );
      }
    }

    if (characters.length === 0) {
      return '';
    }
    for (i = 0; i < Math.min(passwordLength, 1024); i++) {
      let pos = Math.floor(random() * characters.length);
      password = password + characters.charAt(pos);
    }

    return password;
  }, [
    passwordLength,
    includeSymbols,
    includeNumbers,
    includeLowercaseCharacters,
    includeUppercaseCharacters,
    excludeSimilarCharacters,
    excludeAmbiguousCharacters,
  ]);

  const defaultPassword = generatePassword();
  const [password, setPassword] = useState(defaultPassword);

  useEffect(() => {
    setPassword(generatePassword());
  }, [generatePassword]);

  function copyToClipboard(fetchContent) {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari) {
      // Safari
      return navigator.clipboard.write([
        new window.ClipboardItem({
          'text/plain': fetchContent(),
        }),
      ]);
    } else {
      // Firefox & Chrome and everything else
      return fetchContent().then((content) =>
          navigator.clipboard.writeText(content)
      );
    }
  }

  const handleCopy = () => {
    copyToClipboard(() => Promise.resolve(password));
  };

  const handleRegenerate = () => {
    setPassword(generatePassword());
  };

  const Data = useStaticQuery(graphql`
    query {
      saasModernJson {
        PASSWORD_MANAGER_MORE {
          title
          description
          image {
            publicURL
          }
        }
        CROSS_PLATFORM_CLIENTS {
          title
          description
          url
          installGuideUrl
          icon {
            publicURL
          }
        }
      }
      bannerImage: file(
        relativePath: { eq: "image/saasModern/password-generator.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1170, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);
  return (
      <ThemeProvider theme={saasModernTheme}>
        <Fragment>
          <SEO
              title={t('Password Generator')}
              lang={pageContext.lang}
              description={t(
                  'Cryptographic secure password generator to create strong passwords! Passwords are generated in your browser and not transmitted over the internet!'
              )}
          />

          <ResetCSS />
          <GlobalStyle />

          <ContentWrapper>
            <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
              <DrawerProvider>
                <Navbar pageContext={pageContext} />
              </DrawerProvider>
            </Sticky>
            <BannerSectionSmall
                titleText={t('Password Generator')}
                descriptionText={t(
                    'Online password generator for cryptographic secure passwords, generated in your browser'
                )}
                image={Data.bannerImage.childImageSharp.fluid}
            />
            <Box as="section" id="legal_notice_section">
              <Container>
                <Content>
                  <InputLabel htmlFor="password">{t('Password')}</InputLabel>
                  <SliderInputContainer>
                    <TextField
                        value={password}
                        id="password"
                        onFocus={(event) => event.target.select()}
                        readOnly
                    />
                    <IconButton onClick={handleCopy} aria-label={t('Copy password')}>
                      <CopyIcon />
                    </IconButton>
                    <IconButton onClick={handleRegenerate} rightOffset="60px" aria-label={t('Regenerate password')}>
                      <RefreshIcon />
                    </IconButton>
                  </SliderInputContainer>

                  <PasswordLengthSelector
                      passwordLength={passwordLength}
                      setPasswordLength={setPasswordLength}
                  />

                  <br />
                  <CheckBox
                      id="includeSymbols"
                      htmlFor="includeSymbols"
                      labelText={t('Include Symbols ( e.g. -_#+* )')}
                      isChecked={includeSymbols}
                      onChange={setIncludeSymbols}
                  />
                  <br />
                  <CheckBox
                      id="includeNumbers"
                      htmlFor="includeNumbers"
                      labelText={t('Include Numbers ( e.g. 123456 )')}
                      isChecked={includeNumbers}
                      onChange={setIncludeNumbers}
                  />
                  <br />
                  <CheckBox
                      id="includeLowercaseCharacters"
                      htmlFor="includeLowercaseCharacters"
                      labelText={t('Include Lowercase Characters: ( e.g. abcdef )')}
                      isChecked={includeLowercaseCharacters}
                      onChange={setIncludeLowercaseCharacters}
                  />
                  <br />
                  <CheckBox
                      id="includeUppercaseCharacters"
                      htmlFor="includeUppercaseCharacters"
                      labelText={t('Include Uppercase Characters: ( e.g. ABCDEF )')}
                      isChecked={includeUppercaseCharacters}
                      onChange={setIncludeUppercaseCharacters}
                  />
                  <br />
                  <CheckBox
                      id="excludeSimilarCharacters"
                      htmlFor="excludeSimilarCharacters"
                      labelText={t(
                          'Exclude Similar Characters: ( e.g. o,0,O,i,l,1,L )'
                      )}
                      isChecked={excludeSimilarCharacters}
                      onChange={setExcludeSimilarCharacters}
                  />
                  <br />
                  <CheckBox
                      id="excludeAmbiguousCharacters"
                      htmlFor="excludeAmbiguousCharacters"
                      labelText={t(
                          'Exclude Ambiguous Characters: ( e.g. ,.;:\\\'~"/(){}[] )'
                      )}
                      isChecked={excludeAmbiguousCharacters}
                      onChange={setExcludeAmbiguousCharacters}
                  />
                  <br />
                  <br />
                  <Button
                      title={t('GENERATE')}
                      onClick={() => {
                        setPassword(generatePassword());
                      }}
                  />
                  <Button
                      variant="textButton"
                      onClick={handleCopy}
                      aria-label={t('Copy password')}
                      title={t('Copy password')}
                  />
                  <br />
                  <br />
                  <p>
                    {t(
                        'Your passwords deserve to be secure. You can prevent being hacked by brute force or dictionary attacks or being exploited by social engineering by following some rules:'
                    )}
                  </p>
                  <ul>
                    {/* Password security tips */}
                    <li>
                      <p>
                        <Trans i18nKey="unique_passwords_message">
                          Use unique passwords, so different passwords for
                          different accounts. So if one website is being
                          compromised the attacker does not automatically gain
                          access to other potentially more sensitive websites. Use
                          an enterprise password manager like Psono to store and
                          manage all these passwords.
                        </Trans>
                      </p>
                    </li>
                    <li>
                      <p>
                        <Trans i18nKey="random_passwords_message">
                          Use random passwords with at least 16 characters and try
                          to include special characters, numbers, upper and
                          lowercase symbols.
                        </Trans>
                      </p>
                    </li>
                    {/* Add more list items here as necessary */}
                  </ul>
                  <DownloadSection
                      titleText={t(
                          'Use Psono across multiple device types, computers and mobiles'
                      )}
                      descriptionText={t('Cross-platform password manager')}
                      downloadItems={Data.saasModernJson.CROSS_PLATFORM_CLIENTS}
                  />
                  <CTASection
                      content={t('Try Psono for free?')}
                      buttonLink="https://www.psono.pw/"
                      buttonTitle={t('ACCESS PSONO FREE')}
                  />
                </Content>
              </Container>
            </Box>

            <Footer pageContext={pageContext} />
          </ContentWrapper>
        </Fragment>
      </ThemeProvider>
  );
};
